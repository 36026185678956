<template>
  <div>
    <b-table-custom
        :table-data="dataObjectList"
        :table-columns="tableColumns"
        :is-data-loading="isDataLoading"
        @menuClick="menuClick"
    >
      <template #button_event>
        <b-button
            class="ml-1"
            variant="primary"
            size="sm"
            @click="openPlanAddNewModal"
        >
          {{ $t('planSetting.button.add_record') }}
        </b-button>
      </template>
      <template #menu="data">
        <!-- update -->
        <b-dropdown-item v-b-modal:modal-update>
          <font-awesome-icon icon="fa-solid fa-pen-to-square" class="text-info" />
          <span>Cập Nhật</span>
        </b-dropdown-item>
        <!-- Deactivate -->
        <b-dropdown-item v-if="data.item['is_active']" v-b-modal:modal-deactivate>
          <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" />
          <span>Ngừng Kích Hoạt</span>
        </b-dropdown-item>
        <!-- Active -->
        <b-dropdown-item v-else v-b-modal:modal-activate>
          <font-awesome-icon icon="fa-solid fa-check" class="text-success"/>
          <span>Kích Hoạt</span>
        </b-dropdown-item>
      </template>

      <template #cell(actual_buying_price)="{data}">
        <span>{{ currencyFormat(data['item']['actual_buying_price'], 'en-US', 'USD') }}</span>
      </template>
    </b-table-custom>

    <!--add new modal-->
    <b-modal
        ref="modal-add-new"
        id="modal-add-new"
        :title="$t('planSetting.modal.title.add_new', dataObjectSelected)"
        :ok-title="$t('planSetting.modal.button.ok')"
        :cancel-title="$t('planSetting.modal.button.cancel')"
        title-class="text-uppercase"
        modal-class="modal-primary"
        button-size="sm"
        centered
        @ok="addNewPlan"
    >
      <b-form-group>
        <label>Name</label>
        <b-form-input
            class="text-bold text-primary"
            size="sm"
            v-model="dataObjectSelectedClone['name']"
        />
      </b-form-group>
      <b-form-group>
        <label>Type</label>
        <v-select class="select-size-sm"
                  v-model="dataObjectSelectedClone['plan_type_id']"
                  :clearable="false"
                  :reduce="plan_type => plan_type.id"
                  label="name"
                  :options="planTypeList"/>
      </b-form-group>
      <b-form-group>
        <label>CPU Info</label>
        <b-form-input
            size="sm"
            v-model="dataObjectSelectedClone['cpu_info']"/>
      </b-form-group>
      <b-row>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>VCORES</label>
            <b-input-group
                class="input-group-merge"
                append="cores"
                size="sm">
              <b-form-input type="number" min="1" v-model="dataObjectSelectedClone['vcores']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>RAM</label>
            <b-input-group
                class="input-group-merge"
                append="GB"
                size="sm">
              <b-form-input type="number" min="1" v-model="dataObjectSelectedClone['ram']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>DISK</label>
            <b-input-group
                class="input-group-merge"
                append="GB"
                size="sm">
              <b-form-input type="number" min="10" v-model="dataObjectSelectedClone['disk']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>BANDWIDTH</label>
            <b-input-group
                class="input-group-merge"
                append="GB"
                size="sm">
              <b-form-input type="number" v-model="dataObjectSelectedClone['bandwidth']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <label>Location</label>
        <b-form-input
            size="sm"
            v-model="dataObjectSelectedClone['location']"/>
      </b-form-group>
      <b-row>
        <b-col sm="4">
          <money-box class="text-info"
                     v-model="dataObjectSelectedClone['buying_price']"
                     label="Giá mua vào"
          />
        </b-col>
        <b-col sm="4">
          <money-box class="text-success"
                     v-model="dataObjectSelectedClone['selling_price']"
                     label="Giá bán ra"
          />
        </b-col>
        <b-col sm="4">
          <custom-input label="Giá thực tế ($)"
              v-model="dataObjectSelectedClone['actual_buying_price']"/>
        </b-col>
      </b-row>
      <b-form-group
          label="Note">
        <b-form-textarea
            rows="2"
            v-model="dataObjectSelectedClone['note']"
        />
      </b-form-group>

    </b-modal>
    <!--update modal-->
    <b-modal
        ref="modal-update"
        id="modal-update"
        :title="$t('planSetting.modal.title.update_plan', dataObjectSelected)"
        :ok-title="$t('planSetting.modal.button.ok')"
        :cancel-title="$t('planSetting.modal.button.cancel')"
        title-class="text-uppercase"
        modal-class="modal-primary"
        button-size="sm"
        centered
        @ok="updatePlan"
    >
      <b-form-group label="Name">
        <b-form-input
            class="text-bold text-primary"
            size="sm"
            v-model="dataObjectSelectedClone['name']"
        />
      </b-form-group>
      <b-form-group label="Type">
        <v-select v-model="dataObjectSelectedClone['plan_type_id']"
                  :clearable="false"
                  :reduce="plan_type => plan_type.id"
                  label="name"
                  :options="planTypeList"/>
      </b-form-group>
      <b-form-group label="CPU Info">
        <b-form-input
            size="sm"
            v-model="dataObjectSelectedClone['cpu_info']"/>
      </b-form-group>
      <b-row>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>VCORES</label>
            <b-input-group
                class="input-group-merge"
                append="cores"
                size="sm">
              <b-form-input type="number" min="1" v-model="dataObjectSelectedClone['vcores']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>RAM</label>
            <b-input-group
                class="input-group-merge"
                append="GB"
                size="sm">
              <b-form-input type="number" min="1" v-model="dataObjectSelectedClone['ram']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>DISK</label>
            <b-input-group
                class="input-group-merge"
                append="GB"
                size="sm">
              <b-form-input type="number" min="10" v-model="dataObjectSelectedClone['disk']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group>
            <label>BANDWIDTH</label>
            <b-input-group
                class="input-group-merge"
                append="GB"
                size="sm">
              <b-form-input type="number" v-model="dataObjectSelectedClone['bandwidth']"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Location">
        <b-form-input
            size="sm"
            v-model="dataObjectSelectedClone['location']"/>
      </b-form-group>
      <b-row>
        <b-col sm="4">
          <money-box class="text-info"
                     v-model="dataObjectSelectedClone['buying_price']"
                     label="Giá mua vào"
          />
        </b-col>
        <b-col sm="4">
          <money-box class="text-success"
                     v-model="dataObjectSelectedClone['selling_price']"
                     label="Giá bán ra"
          />
        </b-col>
        <b-col sm="4">
          <custom-input label="Giá thực tế ($)"
              v-model="dataObjectSelectedClone['actual_buying_price']"/>
        </b-col>
      </b-row>
      <b-form-group label="Note">
        <b-form-textarea
            rows="2"
            v-model="dataObjectSelectedClone['note']"
        />
      </b-form-group>


    </b-modal>
    <!--deactivate modal-->
    <b-modal
        ref="modal-deactivate"
        id="modal-deactivate"
        :title="$t('planSetting.modal.title.deactivate_plan', dataObjectSelected)"
        :ok-title="$t('planSetting.modal.button.ok')"
        :cancel-title="$t('planSetting.modal.button.cancel')"
        title-class="text-uppercase"
        modal-class="modal-primary"
        button-size="sm"
        centered
        @ok="deactivatePlan"
    >
      <span v-html="$t('planSetting.modal.label.deactivate_plan', dataObjectSelected)"></span>
    </b-modal>
    <!--activate modal-->
    <b-modal
        ref="modal-activate"
        id="modal-activate"
        :title="$t('planSetting.modal.title.activate_plan', dataObjectSelected)"
        :ok-title="$t('planSetting.modal.button.ok')"
        :cancel-title="$t('planSetting.modal.button.cancel')"
        title-class="text-uppercase"
        modal-class="modal-primary"
        button-size="sm"
        centered
        @ok="activatePlan"
    >
      <span v-html="$t('planSetting.modal.label.activate_plan', dataObjectSelected)"></span>
    </b-modal>
  </div>
</template>

<script>

import bTableCustom from '@/views/components/table/BTableCustom.vue'
import MoneyBox from "@/views/components/MoneyBox.vue";

import planService from "@/mixins/utils/axios/plan.service";
import inputHelper from '@/mixins/utils/inputHelper'
import dataRequester from '@/mixins/utils/dataRequester'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import dataObjectList from "@/mixins/utils/dataObjectList";
import CustomInput from "@/views/components/form/CustomInput.vue";

export default {
  name: "PlanSetting",
  components: {
    CustomInput,
    vSelect,
    MoneyBox,
    bTableCustom
  },
  mixins: [inputHelper, dataRequester, dataObjectList],
  directives: {
    Ripple,
  },
  data () {
    let self = this
    return {
      tableColumns: [
        {
          key: 'name',
          formatter(value) {
            return `<strong class="text-primary">${value}</strong>`
          }
        },
        {
          key: 'plan_type_id',
          formatter(value) {
            let planTypeObj = self.planTypeList.find(x => x['id'] === value)
            if (planTypeObj !== undefined) {
              return planTypeObj['name']
            } else {
              return '-'
            }
          }
        },
        {
          key: 'cpu_info'
        },
        {
          key: 'vcores',
          formatter(value) {
            return `${value} cores`
          }
        },
        {
          key: 'ram',
          formatter(value) {
            return `${value} GB`
          }
        },
        {
          key: 'disk',
          formatter(value, index, item) {
            return `${item['disk']} GB ${item['disk_type']}`
          }
        },
        {
          key: 'bandwidth',
          formatter(value) {
            if (value < 0) {
              return `Không giới hạn`
            } else {
              return `${self.numberFormat(value)} GB`
            }
          }
        },
        {
          key: 'location'
        },
        {
          key: 'buying_price',
          formatter(value) {
            return `<span class="text-info">${self.currencyFormat(value)}</span>`
          }
        },
        {
          key: 'selling_price',
          formatter(value) {
            return `<span class="text-success">${self.currencyFormat(value)}</span>`
          }
        },
        {
          key: 'actual_buying_price',
          label: 'Thực giá'
        },
        {
          key: 'note'
        },
        {
          key: 'is_active'
        },
        {
          key: 'menu'
        },
      ],
      isDataLoading: true,
      planTypeList: []
    }
  },
  mounted() {

  },
  created () {
    let self = this
    this.runApi(planService.getPlans(), function (result) {
      self.dataObjectList = result.data
    }, null, function () {
      self.isDataLoading = false
    })
    this.runApi(planService.listPlanType(), function (result) {
      self.planTypeList = result.data
    })
  },
  methods: {
    openPlanAddNewModal () {
      this.dataObjectSelectedClone = {}
      this.$refs['modal-add-new'].show()
    },
    menuClick (rowData) {
      this.dataObjectSelected = rowData
      this.dataObjectSelectedClone = {...rowData}
    },

    deactivatePlan () {
      let self = this
      this.updateRowData(function (rowData) {
        self.runApi(planService.deactivatePlan(rowData['id']), function () {
          self.setItemProperty(rowData, 'is_active', false)
        }, null, null, true)
      })
    },
    activatePlan () {
      let self = this
      this.updateRowData(function (rowData) {
        self.runApi(planService.activatePlan(rowData['id']), function () {
          self.setItemProperty(rowData, 'is_active', true)
        }, null, null, true)
      })
    },
    updatePlan () {
      let self = this
      this.updateRowData(function (rowData) {
        self.runApi(planService.updatePlan({
          id: self.dataObjectSelectedClone['id'],
          ...self.dataObjectSelectedClone
        }), function () {
          self.setItemProperty(rowData, 'name', self.dataObjectSelectedClone['name'])
          self.setItemProperty(rowData, 'plan_type_id', self.dataObjectSelectedClone['plan_type_id'])
          self.setItemProperty(rowData, 'cpu_info', self.dataObjectSelectedClone['cpu_info'])
          self.setItemProperty(rowData, 'vcores', self.dataObjectSelectedClone['vcores'])
          self.setItemProperty(rowData, 'ram', self.dataObjectSelectedClone['ram'])
          self.setItemProperty(rowData, 'disk', self.dataObjectSelectedClone['disk'])
          self.setItemProperty(rowData, 'bandwidth', self.dataObjectSelectedClone['bandwidth'])
          self.setItemProperty(rowData, 'location', self.dataObjectSelectedClone['location'])
          self.setItemProperty(rowData, 'buying_price', self.dataObjectSelectedClone['buying_price'])
          self.setItemProperty(rowData, 'selling_price', self.dataObjectSelectedClone['selling_price'])
          self.setItemProperty(rowData, 'note', self.dataObjectSelectedClone['note'])
        }, null, null, true)
      })
    },
    addNewPlan () {
      let self = this
      this.updateRowData(function (rowData) {
        self.runApi(planService.addNewPlan({
          ...self.dataObjectSelectedClone
        }), function (result) {
          self.setItemProperty(rowData, 'id', result.data['plan_id'])
        }, null, null, true)
      })
    }
  }
}
</script>

<style scoped>

</style>
